.tt-selectable.tt-cursor{
    color: blue;
}

.twitter-typeahead, .tt-input, .tt-menu{
    width: 100%;
}

.typeahead-heading{
    position: relative;
    padding: 5px 0 5px 25px;
    border-bottom: 1px solid #dfe1e6;
    margin-bottom: 5px;
}

.tt-menu{
    padding: 15px 20px 20px;
}

.typeahead-suggestion{
    padding: 2px 0 2px 25px;
}

.tt-selectable{
    cursor: pointer;
}